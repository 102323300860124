
import { reactive, PropType, defineComponent } from 'vue';
import PanelList from '@/components/common/panel-list';
import { billingCycleMap } from '@/constant/field-map';

export default defineComponent({
    components: {
        PanelList
    },
    props: {
        autoRenewInfo: {
            type: Object as PropType< {
                Users: string;
                IntervalType: number;
                Cycles: number;
                StartTime: string;
                TotalPrice: string;
                SubscriptionUUID: string;
                InstallerUUID: string;
            }>,
            default: () => ({}),
            require: true
        },
        info: {
            required: true,
            type: Object as PropType<Record<string, any>>
        }
    },
    setup() {
        const panelListColumn = reactive([
            { prop: 'IntervalType', label: WordList.RenewType },
            // { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime },
            { prop: 'Cycles', label: WordList.RenewTimes },
            { prop: 'number', label: WordList.NumberOfRenewalUser },
            { prop: 'TotalPrice', label: WordList.TotalPriceByMonth }
        ]);
        return { panelListColumn, billingCycleMap };
    }
});

